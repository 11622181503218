// drawerList.scss

.drawer-list {
    width: 350px;
    font-family: "Poppins", sans-serif;
  
    .drawer-header {
      display: flex;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding: 0 10px;
      height: 64px;
  
      h1 {
        font-size: 22px;
        color: #334d74;
        font-weight: 900;
        padding-left: 50px;
      }
  
      .close-icon {
        width: 15px;
        height: 15px;
      }
    }
  
    .accordion {
      border: none;
      box-shadow: none;
      margin: 0;
  
      .MuiAccordionSummary-content,
      .MuiTypography-root,
      .accordion-summary {
        font-family: "Poppins", sans-serif;
        font-size: 13px;
        color: #334d74;
      }
  
      .accordion-summary {
        font-size: 16px;
      }
  
      .accordion-details {
        font-family: "Poppins", sans-serif;
        font-size: 13px;
        background-color: #dfe4eb;

  
        &.default {
          background-color: #e9ecf1;
        }
  
        .list-item-text {
          font-size: 14px;
          padding-left: 45px;
          cursor: pointer;
          margin: 10px;
  
          &.default {
            color: #000;
          }
        }
      }
    }
  }
  
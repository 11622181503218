.initiative {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-column: span 12;
  margin: 10px 0;
}

.init-tabs-list {
  display: flex;
  justify-content: baseline;
  align-items: center;
  flex-wrap: wrap;
}

.download-table {
  width: 142px;
  height: 35px;
  border-radius: 8px;
  border: 1px;
  background: none;
  border: 1px solid #dfe4eb;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #334d74;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px !important;
  cursor: pointer;

  .download-icon {
    text-align: right;
    font-weight: 100;
    // padding-left: 5px !important;
    margin-top: 5px;
  }
}

.init-tab {
  width: 200px;
  height: 35px;
  margin: 5px;
  background-color: #dfe4eb;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  color: #7e8fa9;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
}

.init-tabrows-length {
  background-color: white;
  border-radius: 50%;
  height: max-content;
  min-width: 24px;
  max-width: max-content;
  padding: 1px;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  color: #7889a3;
}

.init-tab-active {
  @extend .init-tab;
  background-color: #334d74;
  color: white;

  .init-tabrows-length {
    color: #334d74;
  }
}

.init-views {
  @extend .initiative;
}

.init-subtab-list {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: -10px;
  grid-column: span 12;
  overflow-x: hidden;
}

.init-subtab {
  @extend .init-tab;
  margin-bottom: 20px;
}

.init-subtab-active {
  @extend .init-tab-active;
}

.init-table {
  // max-width: 100%;
  width: 100%;
  //   height: 400px;
  //   margin-top: 20px;
  // overflow-x: auto;
}


.pbi-chart {
  grid-column: span 12;
  height: 515px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 2px 5px 0px #dae8ff;
  // margin: 30px 0;
}

.reportClass {
  height: 100%;
}

.multiYearByLeverScreen {
  height: 100%;
  background: #CFE5FF;
}



// .init-details-page {
//   display: flex;
//   flex-direction: column;
//   grid-column: span 12;
//   margin-bottom: 50px;
// }

.init-details-header {
  font-size: 24px;
  font-weight: 400;
  color: #334d74;
}

.init-details-list {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.heading {
  font-family: 'Poppins', sans-serif !important;
}

.sub-heading {
  font-family: 'Poppins', sans-serif !important;
  font-size: 16px;
}

.label-type-mand::after {
  content: " *";
  color: red;
}

.input-style {
  height: 50px;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #BBC9FB;
  font-family: 'Poppins', sans-serif !important;
  outline: none;
  width: 100%;
  box-sizing: border-box;
}

.custom-menu {
  .MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
    margin-top: 5px;
    border: 1px solid #BBC9FB;

    // Apply left position for small screens like laptops
   
  }
}
.custom-menu {
  @media (max-width: 1166px) {
    .MuiPaper-root.MuiPopover-paper.MuiMenu-paper {
      left: 1145px !important;
    }
  }
}



.select-style {
  font-family: 'Poppins', sans-serif !important;
  background: #FFF;
  border: 1px solid #BBC9FB;
  border-radius: 5px;
  height: 50px;
  padding-left: 8px;
  box-sizing: border-box;
  width: 100%;
}

.row-style {
  font-family: 'Poppins', sans-serif !important;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  margin-bottom: 16px;
}

.column-style {
  font-family: 'Poppins', sans-serif !important;
  flex: 1 1 22%;
  margin-right: 30px;
}

.input-container-style {
  font-family: 'Poppins', sans-serif !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
}

.impact-year-style {
  font-family: 'Poppins', sans-serif !important;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  padding-top: 8px;
}

.input-props-style {
  height: 50px;
  outline: none;
  width: 100%;
  box-sizing: border-box;
  background: white;
  font-family: 'Poppins', sans-serif !important;
}

.input-category-style {
  flex: 0 1 80%;
  margin-left: 10px;
  height: 50px;
  outline: none;
  width: 100%;
  box-sizing: border-box;
  background: white;
  font-family: 'Poppins', sans-serif !important;
}

.input-category-style-total {
  flex: 0 1 80%;
  margin: 20px 0 0 12px;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 600;
  font-size: 14px;
}

.input-category-per-style {
  flex: 0 1 15%;
  margin-left: 10px;
  height: 50px;
  outline: none;
  width: 100%;
  box-sizing: border-box;
  background: white;
  font-family: 'Poppins', sans-serif !important;
}

.input-category-per-style-per {
  flex: 0 1 15%;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 600;
  font-size: 14px;
  margin: 20px 0 0 0;
}

.input-category-close {
  min-width: 30px;
  min-height: 30px;
  padding: 5px;
  line-height: 1;
  font-size: 14px;
  text-align: center;
  border-radius: 50%;
}

.comment-prop-style {
  height: 50px;
  outline: none;
  width: 149%;
  box-sizing: border-box;
  background: white;
  font-family: 'Poppins', sans-serif !important;
}

.label-style-align {
  font-family: 'Poppins', sans-serif !important;
  font-size: 16px;
  color: #373B5C;
  font-weight: 500;
  margin-bottom: 5px;
  text-align: center;
}

.rs-calendar-table-cell-selected-start .rs-calendar-table-cell-content,
.rs-calendar-table-cell-selected-end .rs-calendar-table-cell-content {
border-radius: 50%; /* Makes the from and to dates round */
background-color: #2e86de; /* Customize the color as needed */
color: white; /* Adjust text color for contrast */
}

.rs-picker-popup .rs-calendar-body {
  padding-left: 15px;
  width: 320px;
  padding-right: 15px;
}

.rs-input-group.rs-input-group-inside .rs-input {
  border: none;
  display: block;
  outline: none;
  width: 100%;
  height: 50px;
}


.rs-calendar-table-cell-in-range .rs-calendar-table-cell-content {
border-radius: 0; /* Keep the range dates square to distinguish the range */
background-color: #d1eaff; /* Customize this color as needed */
color: #2e86de; /* Adjust text color for in-range dates */
}

.custom-date-range-picker .rs-picker-menu {
  width: 400px; /* Adjust the width as needed */
}

.custom-date-range-picker .rs-calendar {
  width: 100%; /* Ensure the calendar inside the picker takes full width */
}

.rs-calendar,
.rs-calendar-header,
.rs-calendar-table,
.rs-btn-xs,
.rs-calendar-table-cell-content,
.rs-calendar-table-cell,
.rs-picker-daterange-header,
.rs-calendar-table-header-cell {
font-family: 'Poppins', sans-serif !important;
font-size: 16px;
}

.heading {
  font-family: "Poppins",sans-serif !important;
  color: #000606;
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 30px;
}

.sub-heading {
  font-family: "Poppins",sans-serif !important;
  color: #000606;
  font-weight: 600;
  font-size: 18px;
  margin: 20px 0px 25px 0px;
}

.label-type {
  font-family: "Poppins",sans-serif !important;
  color: #000606;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 10px;
}
.label-type-mand {
  font-family: "Poppins",sans-serif !important;
  color: #000606;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 10px;
}


.rs-input {
  font-size: 15px;
}

.rs-calendar-body{
  margin: 0px 20px 0px 20px ;
  border: none;
}
.calendar-start{
  width: 360px;
}
.css-jd1zyo-MuiSelect-select-MuiInputBase-input-MuiInput-input.MuiSelect-select{
  background:white !important;
}

.rs-picker.rs-picker-date .rs-picker-input-group:focus-within {
  border-color: #BBC9FB !important;
}

.rs-picker.rs-picker-error .rs-picker-input-group, 
.rs-picker.rs-picker-error .rs-picker-input-group:hover {
  border-color: #BBC9FB !important;
}
  
.custom-select-popover-init-details {
  width: auto !important;
  background: white !important;
  margin-top: 0 !important;
  border-radius: 12px !important;
  max-height: 300px !important;
  // margin-left: 50px !important;
  box-shadow: 0px 2px 5px 0px #dae8ff !important;
  padding: 5px 10px !important;
}
  
.MuiSelect-select {
  display: flex !important;
  align-items: center !important;
}

.line-text {
  display: flex;
  align-items: center;
  text-align: center;

  &::before,
  &::after {
    content: "";
    flex: 1;
    border-bottom: 1px solid #BFBFBF; // Adjust color as needed
  }

  &::before {
    margin-right: 10px; // Space between line and text
  }

  &::after {
    margin-left: 10px; // Space between line and text
  }
}

.file-input-wrapper {
  display: inline-block;
  border-radius: 4px; 
  padding: 5px 0px;
  font-size: 16px; 

  .file-input {
    border: none;
    outline: none; 
    width: 100%;
    height: 100%;
    
    &::-webkit-file-upload-button {
      border: 2px solid #E4D4C799; 
      border-radius: 4px; 
      padding: 6px 20px; 
      background-color: #fff;
      cursor: pointer; 
    }

    &::-ms-browse {
      border: 2px solid #E4D4C799; 
      border-radius: 4px;
      padding: 5px 10px;
      background-color: #fff;
      cursor: pointer;
    }
  }
}

.custom-upload {
  background-color:rgb(0, 77, 205) !important; 
  color: #fff !important; 
  // padding: 8px 50px !important; 
  border-radius: 10px !important; 
  text-transform: capitalize !important;
  width: 164px !important;
    height: 42px !important;
}
.custom-upload-disabled {
    background-color: #ccc; // Background when disabled
    color: #666; // Text color when disabled
    width: 164px !important;
    height: 42px !important;
    border-radius: 10px !important; 
  }


.download-format {
  font-family: "Poppins",sans-serif !important;
  color: #000606;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 10px;
}

.download-opportunity::before {
  content: " *";
  color: red;
}

.download-sample {
  background:none;
  color: rgb(0, 77, 205);
  text-decoration: underline; 
  margin-top: 3px;
 
}

.grid-container-upload{
  display: grid !important;
  grid-template-columns: 30% 30% 30% !important;
}


.container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  align-items: stretch;
  margin: 0 24px;
  gap: 36px;
  row-gap: 12px;
}

@media (max-width: 1280px) {
  .container {
    grid-template-columns: repeat(12, 1fr);
    margin: 0 24;
    gap: 36px;
    row-gap: 0px;
  }
}

@media (max-width: 1024px) {
  .container {
    grid-template-columns: repeat(8, 1fr);
    margin: 0 24px;
    gap: 36px;
  }
}

// @media (max-width: 768px) {
//   .container {
//     grid-template-columns: repeat(6, 1fr);
//     // padding: 0 20px;
//   }
// }

@media (max-width: 375px) {
  .container {
    grid-template-columns: repeat(4, 1fr);
    margin: 0 12px;
    gap: 16px;
  }
}

.container-fullpage {
  @extend .container;
  margin: 0 64px;
}

.init-filter-area {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  grid-column: span 12;

  .init-filter-title {
    display: flex;
    flex-direction: column;
    grid-column: span 3;
    // width: 166px;
    height: 44px;
    width: 30%;

    h4 {
      margin: 0;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      text-align: left;
      width: 76px;
      height: 24px;
    }

    p {
      margin: 0;
      font-family: Poppins;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      text-align: left;
      width: 166px;
      height: 18px;
      color: #6b6b6b;
    }
  }

  .init-filter-list {
    display: flex;
    justify-content: flex-start;
    // grid-column: span 8;
    width: 100%;

    .init-filter-item {
      background: #fff;
      border-radius: 8px;
      height: 40px;
      width: 180px;
      padding: 6px 8px;
      color: #373b5c;
      font-size: 10px;
      font-weight: 500;
      margin-right: 10px;
      box-shadow: 0px 2px 5px 0px #dae8ff !important;
    }
  }
}

// .MuiPopover-paper {
//   width: 230px;
//   margin-left: -10px;
//   margin-top: 10px;
//   padding: 10px;
//   border-radius: 12px !important;
//   max-height: 300px !important;
//   overflow-y: auto !important;
// }

// .MuiMenuItem-gutters {
//   padding: 2px !important;
//   .MuiCheckbox-root {
//     padding: 5px !important;
//     color: #2280ef !important;
//   }
// }

.MuiButtonBase-root .MuiAccordionSummary-root {
  min-height: 30px !important;
}

.MuiAccordionSummary-content {
  margin: 12px 0 !important;
}

.MuiAccordionDetails-root {
  border: 1px solid #c9d0d9;
  border-radius: 8px;
  margin: 0 10px;
  padding: 5px !important;
  max-height: 200px;
  overflow: hidden;
  overflow-y: auto;

  .MuiRadio-root {
    padding: 5px !important;
    margin-left: 10px;
  }
}

.MuiAccordion-root::before {
  position: unset !important;
}

.Mui-expanded {
  margin: 5px 0 !important;
  min-height: auto !important;
  box-shadow: none;
}
.MuiInput-underline::before {
  content: none !important;
}
.MuiInput-underline::after {
  content: none !important;
}

.multi-select-checkbox-list {
  margin-left: -10px !important;
  overflow: hidden;
}

.init-details-page {
  display: flex;
  flex-direction: column;
  grid-column: span 12;
  margin-bottom: 50px;
}

.gray-row {
  background-color: #D6EEEE;
}

.init-details-header {
  font-size: 24px;
  font-weight: 400;
  color: #334d74;
}

.init-details-list {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.init-details-card {
  height: 110px;
  display: flex;
  justify-content: space-between;
  align-items: first baseline;
  background: #fff !important;
  padding: 0 10px;
  border-radius: 10px;
  border-left: 5px solid #42b19e;
  box-shadow: 0px 4px 5px 0px #dae8ff;
}

.init-details-key {
  margin-top: 30px;
  margin-left: 10px;
  font-weight: 600;
  color: #373b5c;
  font-size: 16px;
  min-width:20%;
  // max-width: 60%;
}

.init-details-value {
  font-weight: 400;
  color: #6c757d;
  font-size: 16px;
  margin-top: 5px;
  margin-left: 10px;
  min-width:20%;
  // max-width: 60%;
}

.init-details-desc {
  font-size: small;
  margin-top: 10px;
}

.custom-select-popover-init-details {
  width: auto !important;
  background: white !important;
  margin-top: 0 !important;
  border-radius: 12px !important;
  max-height: 300px !important;
  // margin-left: 50px !important;
  box-shadow: 0px 2px 5px 0px #dae8ff !important;
  padding: 5px 10px !important;
}

.init-details-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px !important;
}

.MuiSelect-select {
  display: flex !important;
  align-items: center !important;
}

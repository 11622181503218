.init-details-page {
    display: flex;
    flex-direction: column;
    grid-column: span 12;
    margin-bottom: 50px;
  }
  
  .init-details-header {
    font-size: 24px;
    font-weight: 400;
    color: #334d74;
  }
  
  .init-filter-list {
    display: flex;
    justify-content: flex-start;
    // grid-column: span 8;
    width: 100%;
  
    .init-filter-item {
      background: #fff;
      border-radius: 8px;
      height: 40px;
      width: 180px;
      padding: 6px 8px;
      color: #373b5c;
      font-size: 10px;
      font-weight: 500;
      margin-right: 10px;
      box-shadow: 0px 2px 5px 0px #dae8ff !important;
    }
  
    .init-filter-item-disabled {
      background: #bbb;
      border-radius: 8px;
      height: 40px;
      width: 180px;
      padding: 6px 8px;
      color: #373b5c;
      font-size: 10px;
      font-weight: 500;
      margin-right: 10px;
      box-shadow: 0px 2px 5px 0px #dae8ff !important;
    }
  }
  
  .custom-select-popover-init-details {
    width: auto !important;
    background: white !important;
    margin-top: 0 !important;
    border-radius: 12px !important;
    max-height: 300px !important;
    // margin-left: 50px !important;
    box-shadow: 0px 2px 5px 0px #dae8ff !important;
    padding: 5px 10px !important;
  }
  
  .init-details-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px !important;
  }
  
  .MuiSelect-select {
    display: flex !important;
    align-items: center !important;
  }
  
  .pg-card-section {
    grid-column: span 12;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    margin: 20px 0px;
  
    .pg-card {
      background: #fff;
      border-radius: 5px;
      padding: 10px;
      width: 25%;
      box-shadow: 0px 2px 5px 0px #dae8ff;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
  
      .pg-card-title {
        font-size: 14px;
        color: #2f2f2f;
      }
  
      .pg-card-values {
        font-weight: 800;
        font-size: 24px;
      }
  
      .pg-card-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        //   padding: 10px;
      }
  
      .pg-card-bottom {
        display: flex;
        flex-direction: row;
        // justify-content: space-between;
        // width: 80%;
      }
  
    }
  }
  
  .pg-link {
    grid-column: span 12;
  }
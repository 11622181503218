body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background: #f7f9fc;
  font-family: "Poppins", sans-serif !important;
}

/* For Firefox */
html {
  scrollbar-width: thin; /* Makes the scrollbar thin */
  scrollbar-color: #888 #f1f1f1; /* Handle color and track color */
}

/* For WebKit browsers (Chrome, Safari, etc.) */
::-webkit-scrollbar {
  width: 8px; /* Width of vertical scrollbar */
  height: 8px; /* Height of horizontal scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar handle */
  border-radius: 4px; /* Rounded corners for the handle */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the handle when hovered */
}

/* For IE and Edge */
body {
  -ms-overflow-style: -ms-autohiding-scrollbar; /* Hides the scrollbar when not in use */
}

.MuiDataGrid-root {
  border: none !important;
  background: none;
}

.MuiDataGrid-virtualScroller {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.MuiDataGrid-virtualScrollerContent {
  border: 1px solid rgba(224, 224, 224, 1) !important;
}
.MuiDataGrid-columnHeaderCheckbox {
  border-top-left-radius: 10px;
}
.MuiDataGrid-columnHeaderTitleContainer {
  text-align: left;
  font-size: 12px;
}

.MuiDataGrid-container--top [role="row"] {
  background: none !important;
}
.MuiDataGrid-columnHeaderTitle {
  text-align: left;
}

.MuiDataGrid-columnHeader--filledGroup .MuiDataGrid-columnHeaderTitleContainer {
  display: flex;
  justify-content: center;
  font-weight: 400;
  font-size: 14px;
}

.MuiDataGrid-columnHeader--filledGroup .MuiDataGrid-columnHeaderTitle {
  font-weight: 400 !important;
}

// .MuiDataGrid-columnHeader--filledGroup {
//   height: 35px !important;
//   margin-top: 30px;
// }

.MuiDataGrid-columnSeparator {
  display: none;
}

.MuiDataGrid-cell .MuiDataGrid-cellEmpty {
  display: none;
}

.MuiDataGrid-columnSeparator--sideRight {
  display: none !important;
  opacity: 0 !important;
  height: 0 !important;
}

.MuiDataGrid-filler {
  display: none;
}

.MuiDataGrid-columnHeaderTitleContainerContent {
  overflow: visible !important;
}

.custom-alert {
    position: fixed;
    top: 20px;
    right: 20px;
    padding: 15px;
    border-radius: 5px;
    color: white;
    z-index: 9999;
    display: flex;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 300px;
    justify-content: space-between;
  }
  
  .custom-alert.success {
    background-color: #4caf50; /* Green for success */
  }
  
  .custom-alert.error {
    background-color: #f44336; /* Red for error */
  }
  
  .custom-alert p {
    margin-left: 10px; /* Space between icon and text */
    flex-grow: 1;
  }
  
  .alert-icon {
    font-size: 30px;
    margin-right: 10px;
  }
  
  .success-icon {
    color: white;
  }
  
  .error-icon {
    color: white;
  }
  
  .close-btn {
    background: none;
    border: none;
    color: white;
    font-size: 20px;
    cursor: pointer;
    padding: 0 10px;
  }

  .init-filter-item {
    background: #fff;
    border-radius: 8px;
    height: 40px;
    width: 180px;
    padding: 6px 8px;
    color: #373b5c;
    font-size: 10px;
    font-weight: 500;
    margin-right: 10px;
    box-shadow: 0px 2px 5px 0px #dae8ff !important;
  }